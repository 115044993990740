function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
//system identification
function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
};
var device_id = IsPC();
var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");

if(system_confirm()=="mac"){isMac=true}
else if(system_confirm()=="win"){isMac=false}
if(device_confirm()==true){device_id=true}
else if(device_confirm()==false){device_id=false}else{};
if (isMac == true) {
  $('.mac_btn_box').css({"display":"flex"});
  $('.mac_btn').css({"display":"block"});
  $('.win_btn_box').css({"display":"none"});
  $('.win_btn').css({"display":"none"});
} else if (!isMac == true) {
  $('.win_btn_box').css({"display":"flex"});
  $('.win_btn').css({"display":"block"});
  $('.mac_btn_box').css({"display":"none"});
  $('.mac_btn').css({"display":"none"});
  
} else if (device_id == false) {
  $('.pc_btn').css({"display":"none"});
};

$(document).click(function(){
    if($(window).width()<1240){
        $(".share_list").css("display", "none");
        $(".menu_list").css("display", "none");
        $(".log_list").css("display", "none");
    }
})
$(".toggle").click(function(e){
    e.stopPropagation();
    $(".share_list").css("display", "none");
    $(".log_list").css("display", "none");
    $(".menu_list").slideToggle();
    if($(".toggle").hasClass('active')){
        $(".toggle").removeClass('active');
    }else{
        $(".toggle").addClass('active');
    }
});
$('.toggle_share').click(function(e){
    e.stopPropagation();
    $(".menu_list").css("display", "none");
    $(".log_list").css("display", "none");
    $(".share_list").slideToggle();
})

// header
function headerResize(){
    if($(window).width()>1200){
        $('.header .login_box').unbind('click');
        $('.header .login_btn .login_box').unbind('hover')
        $('.header .login_btn .login_box').hover(function(){
            $('.header .login_btn .login_box ul').show().css({
                'width':210,
                'marginLeft':0
            });;
        },function(){
            $('.header .login_btn .login_box ul').hide();
        });
    }else{
        // 会员
        $('.header .login_box').unbind('click');
        $('.header .login_btn .login_box').unbind('hover')
        $('.header .login_btn .login_box').unbind('mouseenter')
        $('.header .login_btn .login_box').unbind('mouseleave')
        $('.header .login_box').click(function(e){
            e.stopPropagation();
            $(".menu_list").css("display", "none");
            $(".share_list").css("display", "none");
            if(String($('.header .login_box ul').attr('class')).indexOf('active')<0){
                $(".log_list").slideToggle();
                $('.header_bg').show()
            }else{
                $('.header_bg').hide()
            }
        });
    }
}
headerResize()
var windowWidth=$(window).width();
$(window).resize(function(){
    if(windowWidth!=$(window).width()){
        headerResize()
        windowWidth=$(window).width()
    }
})
$('.header .toggle').click(function(){
    $(this).toggleClass('active')
    $('.header_nav').toggleClass('active')
    try{
        if($('.header .accoutn_box').attr('class').indexOf('active')>-1){
            $('.header .accoutn_box').removeClass('active');
            return false;
        }
        if(String($('.header .login_box ul').attr('class')).indexOf('active')>-1){
            $('.header .login_box ul').removeClass('active')
            return false;
        }
    }catch(e){}
    $('.header_bg').toggle()
})
$('.header_bg').click(function(){
    $(this).hide()
    $('.header .toggle').removeClass('active')
    $('.header_nav').removeClass('active')
    $('.header .accoutn_box').removeClass('active');
    $('.header .login_box ul').removeClass('active');
})